.plans-container{
margin-top: -4rem;
padding: 0 2rem;
display: flex;
flex-direction: column;
gap: 4rem;
position:relative;
}

.plans{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color:white;
    gap:2rem;
    width: 15rem;
    padding: 1.5rem;
}

.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
    
}

.plan>:nth-child(1){
    font-weight: bold;
    font-size:1rem;
}

.plan>:nth-child(2){
    font-weight: bold;
    font-size:3rem;
}

.plan>:nth-child(4){
    font-weight: bold;
    font-size:0.8rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.feature{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.feature>img{
    width:1rem;
}

.plans:nth-child(2)>button{
    color:#00bf63;
}

.plans-blur-1{
    width: 32rem;
    height: 23rem;
    top:6rem;
    left:0rem;
}

.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top:10rem;
    right:0rem;
}

@media  screen and (max-width:768px) {

    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
}