.header{
    display: flex;
    justify-content:space-between;
}


.logo{
    width: 22rem;
    height: 22rem;
    outline: .5rem #2a488a solid;
    outline: .5rem #00bf63 solid;
}

.image-container {
    display: inline-block; /* Makes the container only as wide as the image */
    padding: 1.55rem; /* Optional: Adds space between the image and the border */
    /* border: 0.5rem solid #2a488a; Creates the square border around the image */
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}


.header-menu{
    list-style: none;
    transform: translate(-30%,10%);
    display: flex;
    gap:2rem;
    color:white;
    margin-left: auto;
}

/* .header-menu>li{
    cursor: pointer;
} */

.header-menu>li:hover{
    color:var(--green);
    cursor: pointer;
}

@media  screen and (max-width:768px) {

    .header>:nth-child(2){
        position: fixed;
        right:2rem;
        z-index:99;
    }

    .header-menu{
        /* display: flex; */
        flex-direction: column;
        transform: translateX(-20%);
        justify-content: center;
        align-items: center;
        padding-block: 2rem;
        padding-right: 2rem;;
        background-color: var(--appColor);
    }

    .logo{
         width: 8.5rem;
         height: 8.5rem;
         display:flex;
         justify-content: space-between;
        
 
    }
}