.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color:white;
}

.left-t>:nth-child(1){
    font-size: 1rem;
    color: #00bf63;
    text-shadow: 1px 1px white;
    font-weight: bold;
}

.left-t>:nth-child(2),.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 2rem;

}

.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
    
}

.right-t{
    flex:1;
    position: relative;
}

.right-t>img{
    position:absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 21rem;
    top: 1.5rem;

}

.right-t>:nth-child(1){
    width: 17rem;
    height: 20rem;
    border: 2px solid #00bf63;
    background-color: transparent;
    right: 7rem;
    top: 2rem;
}

.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    top: 4rem;
    right:19rem;
    background: var(--planCard);
}

.arrows{
    display: flex;
    gap: 1rem;
    position:absolute;
    bottom: 1rem;
    left:-7rem;
}

.arrows>img{
    width: 1.5rem;
    cursor:pointer;
}

@media  screen and (max-width:768px) {

    .Testimonials{
        flex-direction: column;
    }

    .left-t>:nth-child(2),.left-t>:nth-child(3){
        font-size: xx-large;

    }

    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .right-t>div{
        display: none;
        position: relative;
    }

    .right-t>img{
        top:0;
        right: 0;
        position: relative;
        align-self:center;
    }

    .right-t>:last-child{
        display: block;
        bottom: 0;
        left: 0;
        gap: 0.5rem;
    }
}