.Reasons{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.left-r{
display: grid;
grid-template-columns: repeat(3, 1fr);
    flex: 1 1;
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.left-r>img{
    object-fit: cover;
}

.left-r>:nth-child(1){
    width:13rem ;
    grid-row: 1/3;
    height: 28rem;
}

.left-r>:nth-child(2){
    
    width:auto;
    height: 16rem;
    grid-column: 2/4;
}

.left-r>:nth-child(3){
    
    width: 13.8rem;
    grid-column: 2/3;
    grid-row: 2;
}

.left-r>:nth-child(4){
    
    width: 10.2rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;
}

.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-r>span{
    font-weight: bold;
    color:#2a488a;
    text-shadow: 1px 1px 2.5px white;
}

.right-r>div{
    font-size:3rem;
    font-weight: bold;
    color:white;
}

.details-r img{
    width:13%;
    height:13%;
    gap:1rem;
}

.details-r div span{
    font-weight: normal;
    font-size: 1rem;
    text-transform: none;
    top:0.5rem;
    display:flex;
    align-items: center;
    flex-direction: column;
    /* margin-top: -4%; */
}