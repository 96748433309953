.Join{
    display: flex;
    padding: 0 2rem;
    gap:10 rem;
}

.left-j{
    color: white;
    font-size:3rem;
    font-weight: bold;
    text-transform: uppercase;
    position:relative;
}

.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid #00bf63;
    border-radius: 20%;
    margin: -10px 0;
}

.left-j>div{
    display: flex;
    gap: 1rem;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container{
    display: flex;
    transform: translateX(50%);
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color:var(--lightgray)
}

::placeholder{
    color:var(--lightgray)
}

.btn-j{
    background:var(--green);
    color: white;
}

@media  screen and (max-width:768px) {

    .Join{
        flex-direction: column;
        gap: 1rem;
    }

    .left-j{
        font-size: large;
        flex-direction: column;
    }

    .right-j{
        padding: 2rem;
        padding-left: 2rem;
    }

.email-container{
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    width: 80%; /* Adjust the width as needed for smaller size */
    margin-left: 0;
    margin-right: auto; /* Align to the left */
  }

  .email-container input,
  .email-container .btn-j {
    width: 100%;
    margin: 0.5rem 0;
  }

  .email-container input {
    margin-left: 0;
    display: flex;
    justify-content: center;
  }

}