.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding:0.2rem;
}

.programs-header{
    display: flex;
    justify-content: center;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    color:white;
    text-transform: uppercase;
    font-style: italic;
}

.programs-categories{
    display: flex;
    gap: 1rem;
}

.category{
    display: flex;
    flex-direction: column;
    background-color: gray;;
    gap: 1rem;
    padding:2rem;
    color:white;
    justify-content: space-between;
}

.category>:nth-child(1){
    font-weight: bold;
    font-size: 1rem;
}

.category>:nth-child(2){
    line-height: 20px;
    font-size: 0.8rem;
}

.join-now{
    align-items: center;
    display:flex;
    gap: 2rem;
}

.join-now>img{
    width: 1rem;
}

.category:hover{
    background: var(--planCard);
    cursor: pointer;
}

@media  screen and (max-width:768px) {

    .programs-header{
        flex-direction:column;
        gap: 1rem;
        font-size:x-large;
        align-items:center;
        justify-content: center;
        margin-top:2rem;

    }

    .programs-categories{
        flex-direction:column;
        
    }
}